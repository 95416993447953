// 顺序 父子关系 按数据顺序及 children 确定
// clientType 默认为 web
// 有url的 type 默认为 sys_page,没有的默认为 menu ，其他情况需要进行指定
// code 默认为 url 若相同url 对应多个菜单 则code需要处理
// 若没有 url 则code自定义，避免重复就可以
export default [{
  'name': '组织架构',
  'code': 'org_manage',
  'sys': 'ucenter',
  'roles': ['rs', 'yz', 'fyz', 'sj', 'fsj'],
  'icon': 'icon-jigoushezhi',
  'children': [{
    'name': '组织架构设置',
    'sys': 'ucenter',
    'roles': ['rs', 'yz', 'fyz', 'sj', 'fsj'],
    'url': 'org.manage'
  },
  {
    'name': '职务管理',
    'roles': ['rs', 'yz', 'fyz', 'sj', 'fsj'],
    'sys': 'ucenter',
    'url': 'org.position'
  },
  {
    'name': '组织架构类型',
    'roles': ['rs'],
    'sys': 'ucenter',
    'url': 'org.type'
  }]
},
{
  'name': '权限管理',
  'icon': 'icon-a-zu1446',
  'roles': ['rs'],
  'sys': 'ucenter',
  'code': 'author',
  'children': [{
    'name': '用户管理',
    'roles': ['rs'],
    'sys': 'ucenter',
    'url': 'sys.user'
  },
  {
    'name': '角色管理',
    'sys': 'ucenter',
    'url': 'sys.role'
  }]
},
{
  'name': '系统管理',
  'roles': ['rs', 'yz', 'fyz', 'sj', 'fsj'],
  'icon': 'icon-a-zu1443',
  'sys': 'ucenter',
  'code': 'sys_manage',
  'children': [{
    'name': '系统配置',
    'sys': 'ucenter',
    'url': 'config.sys'
  },
  {
    'name': '菜单管理',
    'sys': 'ucenter',
    'url': 'sys.menu'
  },
  {
    'name': '功能管理',
    'sys': 'ucenter',
    'url': 'sys.fun'
  },
  {
    'name': '可选项配置',
    'sys': 'ucenter',
    'url': 'config.select'
  },
  {
    'name': '公告管理',
    'roles': ['rs', 'yz', 'fyz', 'sj', 'fsj'],
    'sys': 'ucenter',
    'url': 'sys.placard'
  }]
}]
